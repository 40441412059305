<template>
  <view-container name="search">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.search.title') }}
          </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <a-input-search v-model="search" @search="getUsers" :placeholder="$t('search.userby')" size="large" ref="search">
        <a-button slot="enterButton">
          <Icon :size="24" type="search"/>
        </a-button>
      </a-input-search>

      <User v-for="user in users" :key="user.id" :user="user"/>
    </view-content>
  </view-container>
</template>

<script>
import Icon from '@/components/Icon'
import User from '@/components/User'
import { mapState } from 'vuex'
import { debounce } from '@/helpers'

const debounced = debounce(1000)

export default {
  name: 'Search',
  components: {
    Icon,
    User
  },
  created () {
    this.getUsers()
  },
  beforeDestroy () {
    this.resetStore()
  },
  computed: {
    ...mapState('search', ['users', 'loading']),
    search: {
      get () {
        return this.$store.state.search.search
      },
      set (search) {
        this.setSearch(search)

        this.debouncedeSearch()
      }
    }
  },
  methods: {
    getUsers () {
      this.$store.dispatch('search/getUsers')
    },
    setSearch (search) {
      this.$store.dispatch('search/setSearch', search)
    },
    resetStore () {
      this.$store.dispatch('search/resetStore')
    },
    debouncedeSearch () {
      debounced(async () => {
        await this.getUsers()
      })
    }
  }
}
</script>
